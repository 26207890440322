/**
 * Popover
 * @requires https://getbootstrap.com
 * @requires https://popper.js.org/ // provided with Bootstrap 5 note by René
 */

//import bootstrap from 'bootstrap'
import { Popover } from 'bootstrap'

export default (() => {
  const popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  )

  /* eslint-disable no-unused-vars, no-undef */
  const popoverList = popoverTriggerList.map(
    (popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl)
  )
  /* eslint-enable no-unused-vars, no-undef */
})()
